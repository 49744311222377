@import "./Mixins.scss";

.noBorder {
    border: none !important;
}

.hideWrapper {
    .wrapper {
        display: none !important;
    }
}

.opacity0 {
    opacity: 0
}

.opacity1 {
    opacity: 1;
}

.paddingTop18 {
    padding-top: 18px;
}

.flexGap20 {
    gap: 20px;
}

.flexCentre {
    align-items: center;
}

.flexStart {
    align-items: flex-start;
}

.flexEnd {
    align-items: flex-end;
}

.padding1 {
    padding: 8px;
}

.padding2 {
    padding: 18px;
}

.padding3 {
    padding: 24px;
}

.padding4 {
    padding: 32px;
}

.padding5 {
    padding: 64px;
}

.paddingRight1 {
    padding-right: 8px;
}

.paddingRight2 {
    padding-right: 18px;
}

.paddingRight3 {
    padding-right: 24px;
}

.paddingRight4 {
    padding-right: 32px;
}

.paddingRight5 {
    padding-right: 64px;
}

.actionContainerTD {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    padding-right: 16px;
}

.flexContainer {
    @include verticalContainer;
}

.borderBottomContainer {
    @include borderBottomContainer
}

.subTitleStyle {
    @include subTitleStyle
}

.horizontalContainer {
    @include horizontalContainer;
    border-bottom: 1px solid var(--neutral-grey-300);
}

.container50Percent {
    width: 50%;
    @include contentContainer;
}

.container60Percent {
    width: 60%;
    @include contentContainer;
}

.container70Percent {
    width: 70%;
    @include contentContainer;
}

.stretchContainerItems {
    align-items: stretch;
}

.growOne {
    flex-grow: 1;
}

.clickablePointer {
    cursor: pointer;
}

.checkboxContainer {

    /* Set global checkbox background color as black*/
    .checkboxElementsContainer input:checked~.checkboxCheckmark {
        background-color: #000000;
    }

    .checkboxElementsContainer input:indeterminate~.checkboxCheckmark {
        background-color: #000000;
    }
}