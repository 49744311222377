.agx-alert {
  position: fixed;
  left: calc(50% - 200px);
  top: 90px;
  width: 400px;
  height: 50px;
  background: #000;
  color: #f3f3f3;
  text-align: center;
  border-radius: 30px;
  z-index: 9999;

  span {
    display: block;
    margin: 12px;
  }
}