.partnerPage {
  gap: 32px;

  .partnerPageHeader {
    padding-bottom: 16px;
    border-bottom: 1px solid var(--neutral-grey-300, #dfdfdf);

    .partnerPageHeader-topLeft {
      gap: 6px;

      .partnerPageHeader-topLeft-entityTitle {
        gap: 16px;

        > .imageContainer {
          padding-right: 0px;
        }
      }
    }

    .partnerPageHeader-topRight {
      gap: 28px;
    }

    .partnerPageHeader-dateFilters {
      gap: 8px;

      > .agxDatePicker {
        min-width: 150px;
      }
    }

    .partnerPageHeader-exportButton.agx-button > svg {
      fill: #fff;
    }
  }

  .partnerPageTabs {
    padding: 16px 0px;
    gap: 20px;
    border-bottom: 1px solid var(--neutral-grey-300, #dfdfdf);

    > .header {
      color: var(--neutral-grey-500, #b9b9b9);

      &.strong {
        color: var(--urbanx-black, #000);
      }
    }
  }

  .reportCards {
    .AgxCard {
      background: var(--neutral-grey-200, #f5f5f5);
    }
  }

  .partnershipInfo {
    background: var(--neutral-grey-200, #f5f5f5);
  }
}
