.addTeamsSection__manageTeams {
  background: var(--neutral-grey-200);
  padding: 16px; }

.addTeamsContainer__manageTeams {
  background: var(--neutral-grey-200);
  padding: 16px;
  border-radius: 16px; }

.teamSection__manageTeams {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 0;
  border-top: 1px solid var(--neutral-grey-300); }
