@import "../../Mixins.scss";

.titleBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 11px 17px;
    background-color: var(--urbanx-black);
}

.headerRow {
    display: flex;
    margin: 24px 24px 32px 24px;
    align-items: center;
}

.breadcrumb__header {
    color: var(--neutral-grey-600);
}

.headerLogo {
    margin-right: 16px;

    @include desktop {
      height: 56px;
    }
  
    @include mobile {
      padding-left: 20px;
    }
}

.titleLogo {
    height: 32px;
    width: 32px;
}

.headerContaineronError {
    margin-bottom: 25px;
}

.popupMenu {
    border: 1px solid var(--neutral-grey-300);
    border-radius: 7px;
    position: absolute;
    right: 20px;
    top: 60px;
    background: #ffffff;
    min-width: 270px;
    z-index: 10;
}

.menuDivider {
    height: 1px;
    width: 100%;
    background: var(--neutral-grey-300);
}

.hideMenu {
    display: none !important;
}

.extraTitle {
    display: flex;
    align-self: center;
    padding-left: 5px;
    color: var(--neutral-grey-600)
}

.contractCard {
    align-self: flex-start !important;
    max-width: 800px;
    align-self: center;
    border-radius: 16px;

    > .propertyImage {
        width: 180px;
        height: 135px;
    }
}