.noBorder {
  border: none !important; }

.hideWrapper .wrapper {
  display: none !important; }

.opacity0 {
  opacity: 0; }

.opacity1 {
  opacity: 1; }

.paddingTop18 {
  padding-top: 18px; }

.flexGap20 {
  gap: 20px; }

.flexCentre {
  align-items: center; }

.flexStart {
  align-items: flex-start; }

.flexEnd {
  align-items: flex-end; }

.padding1 {
  padding: 8px; }

.padding2 {
  padding: 18px; }

.padding3 {
  padding: 24px; }

.padding4 {
  padding: 32px; }

.padding5 {
  padding: 64px; }

.paddingRight1 {
  padding-right: 8px; }

.paddingRight2 {
  padding-right: 18px; }

.paddingRight3 {
  padding-right: 24px; }

.paddingRight4 {
  padding-right: 32px; }

.paddingRight5 {
  padding-right: 64px; }

.actionContainerTD {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  padding-right: 16px; }

.flexContainer {
  display: flex;
  flex-direction: column; }

.borderBottomContainer {
  border-bottom: 1px solid var(--neutral-grey-300);
  padding-bottom: 16px;
  display: flex; }

.subTitleStyle {
  font-size: 18px;
  line-height: 20.7px;
  font-weight: 400;
  color: var(--urbanx-black); }

.horizontalContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  border-bottom: 1px solid var(--neutral-grey-300); }

.container50Percent {
  width: 50%;
  padding: 20px;
  background: var(--neutral-grey-200);
  border-radius: 16px; }

.container60Percent {
  width: 60%;
  padding: 20px;
  background: var(--neutral-grey-200);
  border-radius: 16px; }

.container70Percent {
  width: 70%;
  padding: 20px;
  background: var(--neutral-grey-200);
  border-radius: 16px; }

.stretchContainerItems {
  align-items: stretch; }

.growOne {
  flex-grow: 1; }

.clickablePointer {
  cursor: pointer; }

.checkboxContainer {
  /* Set global checkbox background color as black*/ }
  .checkboxContainer .checkboxElementsContainer input:checked ~ .checkboxCheckmark {
    background-color: #000000; }
  .checkboxContainer .checkboxElementsContainer input:indeterminate ~ .checkboxCheckmark {
    background-color: #000000; }

.rotate_90__marketing {
  transform: rotate(90deg); }

.tdAddPackage__marketing {
  background: var(--neutral-grey-200);
  padding-left: 16px; }

.thPackageNameContainer__marketing {
  display: flex;
  align-items: center;
  gap: 5px; }

.sectionStyle {
  padding: 15px;
  background: var(--neutral-grey-200);
  border-radius: 20px; }

.marketingPackagesList {
  list-style: none;
  padding-left: 0; }

.marketingPackagesList li {
  padding: 9px 0; }

.moreOptions {
  position: relative;
  display: inline-block; }

.moreOptionsContent {
  position: absolute;
  top: 60%;
  left: 0;
  width: 100px;
  z-index: 2;
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.1); }
  .moreOptionsContent div {
    width: -webkit-fill-available;
    width: -moz-available;
    cursor: pointer;
    padding: 10px 0 10px 10px; }
