@import '../../Common.scss';

.searchText__campaigns {
  margin-right: 20px;

  .wrapper input {
    width: 16rem !important;
  }
}

.propertyImage {
  width: 70px;
  height: 54px;
  border-radius: 10px;

  &.archived {
    filter: grayscale(100%);
  }
}

.iconLabel {
  display: flex;
  align-items: center;
  gap: 8px;

  >svg {
    width: 18px;
    height: 19px;
    fill: #000;
  }
}

.editCampaignsSection {
  width: 50%;
  padding: 15px;
  background: var(--neutral-grey-200);
  border-radius: 20px;
}

.loadingText_campaigns {
  font-size: 24px;
  color: var(--neutral-grey-500);
}

.campaignsTable .tableRowStyle:first-child {
  background: none;
  cursor: default;
}

.tableRowStyle:hover {
  background-color: var(--neutral-grey-100);
  cursor: pointer;
}

.hover-container {
  position: relative;
}

.hover-element {
  background-color: #ffffff;
  padding: 15px 15px 0;
  transition: transform 0.3s ease;
  position: absolute;
  right: 0;
  top: 35px;
  width: max-content;
  z-index: 100;
  box-shadow: 1px 1px 3px var(--neutral-grey-200);
  font-size: 15px;
  font-weight: normal;
}

.hover-element a {
  display: block;
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
}

.currentTab {

  &:hover,
  &:focus {
    border: none !important;
    outline: none !important;
  }

  &.formerTab {
    color: var(--neutral-grey-500) !important;

    &:hover,
    &:focus {
      border: none !important;
      outline: none !important;
    }
  }
}

.restoreButton {
  color: var(--urbanx-orange) !important;
  border: 1px solid var(--urbanx-orange) !important;

  &:hover {
    outline: 1px solid var(--urbanx-orange) !important;
  }
}

.filters__campaigns {
  padding: 20px 0 20px 0;
}

.filterTabs__campaigns {
  gap: 20px;
}

.contractStatus {
  font-size: 14px;
  font-weight: 400;
  padding-left: 4px;
}