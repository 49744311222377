.panel {
  padding: 20px;
  border-radius: 16px;
  background: var(--neutral-grey-200, #f5f5f5);

  .panel-headerRow {
    border-bottom: 1px solid var(--neutral-grey-300, #dfdfdf);
    padding-bottom: 16px;
    justify-content: space-between;
  }
}
