.sticky {
  position: fixed;
  top: 0;
  right: 60px;
  width: calc(80% - 96px);
  padding-top: 20px;
  background: #ffffff;
  z-index: 100;
}

.stickyMenu {
  position: fixed;
  top: 75px;
  right: 60px;
  width: calc(80% - 96px);
  padding-top: 20px;
}

.addMarginToPanelContainer {
  margin-left: 200px;
  padding-top: 76px;
}

.leftMenu {
  width: 200px;
}

.leftMenuLinkStyle {
  text-decoration: none;
  :hover {
    font-weight: 500;
    opacity: 1;
  }
  padding-left: 8px;
}

.panelContainer {
  padding: 32px 40px 16px;
  background-color: var(--neutral-grey-200);
  border-radius: 10px;
}

.panelTitleContainer {
  border-bottom: 1px solid var(--neutral-grey-300);
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
  left: -40px;
  width: 100%;
}
.panelContainer .panelTitleContainer:last-child {
  border-bottom: none;
}
.hide {
  display: none !important;
}
.panelTitleContainer:has(a.hide) {
  border: none;
  padding: 0;
}
.disabledDiv {
  pointer-events: none;
  opacity: 0.4;
}

.strong {
  font-weight: 500;
  opacity: 0.6;
}
.tickBox {
  display: inline-flex;
  position: relative;
  top: 1px;
}

.circularProgress {
  display: inline-flex;
  position: relative;
  top: 3px;
}

.loadingAnimation {
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.loadingAnimationInner {
  position: fixed;
  top: calc((100% - 400px)/2);
  left: calc((100% - 400px)/2);
}

.label {
  font-size: 18px;
  font-weight: 400;
  line-height: 115%;
  letter-spacing: 0.01px;
}

$color-complete: var(--UI-Success, #54CF09);
$color-incomplete: rgba(0, 0, 0, 0.60);

.completionStatus {
  font-size: 10px;
  font-weight: 400;
  line-height: 165%;
  letter-spacing: 0.06px;

  &--complete {
    color: $color-complete;
  }

  &--incomplete {
    color: $color-incomplete;
  }
}
