.colorPickerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 8px;
  gap: 6px;
  background: #ffffff;
  border: 1px solid var(--neutral-grey-400);
  border-radius: 8px;
  width: 173px; }
  .colorPickerContainer > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0px;
    isolation: isolate;
    width: -webkit-fill-available;
    width: -moz-available; }
    .colorPickerContainer > .header > .labelLeft {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 4px; }

.color {
  width: 24px;
  height: 24px;
  border: 1px solid var(--neutral-grey-400);
  border-radius: 4px; }

.swatch {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: inline-block;
  cursor: pointer; }

.popover {
  position: absolute;
  z-index: 2; }

.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px; }
