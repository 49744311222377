$left-column-width: 80%;
$column-padding: 20px;

.files-panel.panelContainer {
  padding-top: 20px;
  padding-bottom: 40px;

  .panelTitleContainer {
    padding-bottom: 10px;
    margin-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
    margin-left: 40px;
    margin-right: 40px;

    .body.medium {
      font-size: 20px;
      font-weight: 400;
    }
  }

  .textInput {
    .wrapper {
      width: 100%;
    }
  }

  .label.medium {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 6px;
    &.margin-0 {
      margin-bottom: 0;
    }
  }

  .divider {
    margin: 20px 0px 20px;
  }

  .left-header {
    width: $left-column-width;
  }
  .right-header {
    padding-left: $column-padding;
  }
}

.file-document {
  width: $left-column-width;

  &-container {
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    background-color: #fff;
    border: 1px solid var(--neutral-grey-400);
    border-radius: 8px;
    gap: 6px;
    padding: 4px;
    margin: 4px 0;
    -webkit-transition: background .125s;
    transition: background .125s;

    &:hover {
      background-color: var(--neutral-grey-200);
      cursor: pointer;
    }
  }

  &-leftIcon {
    background: var(--neutral-grey-400);
    padding: 8px;
    border-radius: 4px;
    width: 24px;
    height: 24px;

    g {
      scale: 1.3;
    }
  }

  &-body {
    padding: 6px 4px;
    font-weight: 400;
  }

  &-size {
    color: var(--neutral-grey-500);
    margin: 0 12px;
  }

  &-download {
    padding: 8px;
    width: 24px;
    height: 24px;
  }

  &-date {
    padding-top: $column-padding;
    padding-left: $column-padding;
  }
}