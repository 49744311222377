.executive-dashboard-content {
  gap: 40px;

  .metrics-data {
    gap: 20px;

    .breakeven-table {
      border: 1px solid #0000001a;
      border-radius: 8px;
      display: grid;

      .breakeven-table-header,
      .breakeven-table-row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        align-items: center;
      }

      .breakeven-table-header {
        justify-content: space-between;
        background-color: #f5f5f5;
        padding: 16px;
        gap: 16px;
      }

      .breakeven-table-row {
        border-top: 1px solid #0000001a;
        justify-content: space-between;

        .breakeven-cell {
          color: var(--UrbanX-Black, #000);
          text-align: right;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 125%; /* 17.5px */
          letter-spacing: 0.06px;
          padding: 16px;

          &.left {
            text-align: left;
          }

          &:not(.left) {
            text-align: right;
          }

          &.strong {
            font-weight: 500;
            opacity: 100%;
          }

          &.positive {
            color: #47ba00;
          }

          &.negative {
            color: #f94a3f;
          }
        }

        .breakeven-cell-hidden {
          visibility: hidden;
          padding: 16px;
        }
      }

      .breakeven-table-row:hover {
        background-color: #fafafa;
        cursor: pointer;
      }
    }
  }
}

.yearmonth-dropdown {
  background-color: #ffffff;
  border: none;
  text-align: right;
  font-size: 14px;

  option {
    padding: 10px;
    text-align: left;
    background-color: white;
    height: 200px;
  }
}

.key-metrics-header.activeHeader h5,
.run-rate-header.activeHeader h5 {
  color: black;
}

.key-metrics-header.inactiveHeader h5,
.run-rate-header.inactiveHeader h5 {
  color: #787878;
}

.metrics-header {
  border-bottom: 1px solid #dfdfdf;
  min-height: 52px;
  padding-bottom: 16px;

  .metrics-header-left {
    gap: 20px;
  }

  .metrics-header-right {
    gap: 16px;
    display: flex;
    align-items: center;
  }

  .agx-button.primary {
    position: relative;
    display: flex;
  }
}

.run-rate {
  color: var(--neutral-grey-500);
}

.breakeven {
  gap: 48px;
  margin-top: 40px;
  padding-bottom: 20px;
}

.breakeven-header {
  font-weight: 500;
  font-size: 14px;
  text-align: right;
}

.breakeven-blank {
  visibility: hidden;
  color: #f5f5f5;
}

.selected img {
  margin-left: 8px;
  vertical-align: middle;
  width: 13px;
  height: 10px;
}

.selected {
  display: block;
  justify-content: space-between;
}

.dropdown-menu {
  position: absolute;
  background: white;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  width: 100px;
  z-index: 1000;
  gap: 6px;
}

.dropdown-menu button {
  display: flex;
  width: 100%;
  padding: 8px;
  border: none;
  background: none;
  text-align: left;
  font-size: 15px;
  justify-content: space-between;
  align-items: center;
}

.dropdown-menu button:hover {
  background-color: #f0f0f0;
}

@media print {
  body * {
    visibility: hidden;
  }
  .metrics-data,
  .metrics-data * {
    visibility: visible;
  }
  .metrics-data {
    position: absolute;
    left: 0;
    top: 0;
  }
  .breakeven-table-header {
    background-color: #f5f5f5 !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
