.errorContainer__error {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px; }

.circle__error {
  width: 160px;
  height: 160px;
  border-radius: 80px;
  background: var(--neutral-grey-300);
  display: flex;
  justify-content: center;
  align-items: center; }

.smallTextStyle_error {
  font-size: 20px;
  font-weight: 300;
  line-height: 33px;
  text-align: center;
  color: var(--urbanx-black); }

.largeTextStyle__error {
  font-size: 48px;
  font-weight: 300;
  line-height: 62.4px;
  text-align: center;
  color: var(--urbanx-black); }
