.listingsTable {
  padding-bottom: 30px;
  width: 100%;

  .listingsRow {
    &:first-child {
      background: none;
      cursor: default;
    }
  }
}
