table {
  border-spacing: 0px 0px; }

td,
th {
  border-bottom: 1px solid var(--neutral-grey-300);
  padding-bottom: 16px;
  padding-top: 16px; }

.agencyTableLinkStyle__dashboard {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.5px;
  color: var(--neutral-grey-600);
  text-decoration: none; }

.errorTextStyle__dashboard {
  font-size: 24px;
  color: var(--neutral-grey-500); }

.agencyLogo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: contain; }
