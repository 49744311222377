.network-background {
  .executive-dashboard-content {
    gap: 40px;

    .metrics-header {
      border-bottom: 1px solid #dfdfdf;
      min-height: 52px;
      padding-bottom: 16px;

      .metrics-header-left {
        gap: 20px;

        .key-metrics-header,
        .run-rate-header {
          &.activeHeader {
            color: black;
          }
        }

        .key-metrics-header,
        .run-rate-header {
          &.inactiveHeader {
            color: #787878;
          }
        }
      }

      .metrics-header-right {
        gap: 16px;
        display: flex;
        align-items: center;

        .dropdown-menu {
          position: absolute;
          background: white;
          border: 1px solid #dfdfdf;
          border-radius: 8px;
          width: 100px;
          gap: 6px;
        }

        .dropdown-menu button {
          display: flex;
          width: 100%;
          padding: 8px;
          border: none;
          background: none;
          text-align: left;
          font-size: 15px;
          justify-content: space-between;
          align-items: center;
        }

        .dropdown-menu button:hover {
          background-color: #f0f0f0;
        }
      }
    }

    .metrics-data {
      gap: 20px;

      .breakeven-table {
        border: 1px solid #0000001a;
        border-radius: 8px;
        display: grid;

        .breakeven-table-header,
        .breakeven-table-row {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
          align-items: center;
        }

        .breakeven-table-header {
          justify-content: space-between;
          background-color: #f5f5f5;
          padding: 16px;
          gap: 16px;

          .breakeven-blank {
            visibility: hidden;
            color: #f5f5f5;
          }

          .breakeven-header {
            font-weight: 500;
            font-size: 14px;
            text-align: right;

            &.left {
              text-align: left;
            }
          }
        }

        .breakeven-table-row {
          border-top: 1px solid #0000001a;
          justify-content: space-between;

          &.total {
            background-color: #f5f5f5;
          }

          .breakeven-cell {
            color: var(--UrbanX-Black, #000);
            text-align: right;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 125%;
            letter-spacing: 0.06px;
            padding: 16px;

            &.left {
              text-align: left;
            }

            &:not(.left) {
              text-align: right;
            }

            &.strong {
              font-weight: 500;
              opacity: 100%;
            }

            &.positive {
              color: #47ba00;
            }

            &.negative {
              color: #f94a3f;
            }

            .breakeven-cell-hidden {
              visibility: hidden;
              padding: 16px;
            }
          }
        }

        .breakeven-table-row:hover {
          background-color: #fafafa;
          cursor: pointer;
        }
      }
    }
  }

  @media print {
    body * {
      visibility: hidden;
    }
    .metrics-data,
    .metrics-data * {
      visibility: visible;
    }
    .metrics-data {
      position: absolute;
      left: 0;
      top: 0;
    }
    .breakeven-table-header {
      background-color: #f5f5f5 !important;
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
  }
}
