.borderBottom {
  border-bottom: 1px solid var(--neutral-grey-300); }

.alignCenter {
  align-self: center; }

.firstColStyle__marketing {
  display: flex;
  gap: 10px;
  align-items: center; }

.firstColTextboxStyle {
  margin-left: 7px; }
