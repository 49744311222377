@import '../../Common.scss';

.companyDetailsPanel {
  padding: 20px;
  /* Neutral/Grey 200 */

  background: var(--neutral-grey-200);
  border-radius: 16px;
}

.companyDetailsPanel .agency-codes-individual-headers {
  align-items: center;
}
