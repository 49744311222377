.partnersContent {
  gap: 40px;

  border-radius: 24px;
  background: #fff;
  width: 100%;

  .partnersContentHeader {
    gap: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--neutral-grey-300, #dfdfdf);

    .partnersContentHeaderTabs {
      gap: 20px;

      > .header {
        color: var(--neutral-grey-500, #b9b9b9);

        &.strong {
          color: var(--urbanx-black, #000);
        }
      }
    }
  }

  .partnersList {
    border: 1px solid var(--Transparent-10-Black, rgba(0, 0, 0, 0.1));
    border-radius: 16px;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3);

    .partnersTitle {
      font-weight: 400;
    }

    > .partnerItem {
      padding: 12px 18px;
      gap: 20px;
      cursor: pointer;

      > .imageContainer {
        padding-right: 0px;
      }

      &:not(:first-child):hover {
        background: var(--neutral-grey-200, #f5f5f5);
      }

      &:first-child {
        padding: 16px 18px;
        border-bottom: 1px solid var(--Transparent-10-Black, rgba(0, 0, 0, 0.1));
        background: var(--neutral-grey-200, #f5f5f5);
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }

      &:not(:first-child):not(:last-child) {
        border-bottom: 1px solid var(--Transparent-10-Black, rgba(0, 0, 0, 0.1));
      }
    }
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  .partnerPage,
  .partnerPage * {
    visibility: visible;
  }
  .partnerPage {
    position: absolute;
    left: 0;
    top: 0;

    .multiOptionButton {
      visibility: hidden;
      display: none;
    }

    .partnerPageTabs {
      > .header:not(.strong) {
        visibility: hidden;
        display: none;
      }
    }

    .AgxCard {
      break-inside: avoid;
    }

    .reportCards {
      display: flex;
      flex-direction: column;
    }
  }
}
