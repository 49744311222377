.setBudgetModalContent {
  padding: 32px 40px;
  gap: 16px;
  max-height: 60vh;

  .budgetTable {
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;

    tr {
      padding: 16px 12px;
    }

    td,
    th {
      text-align: right;
      padding: 16px 8px;

      &.left {
        text-align: left;
      }
    }
  }

  .budgetScrollableContent {
    max-height: 800px;
    overflow-y: scroll;
  }
}

.setBudgetButtons {
  padding: 32px 20px;
}
