.contentWithMenu {
  display: flex;
  width: 100%; }

.contentContainer {
  width: 80%; }

.menuContainer {
  display: flex;
  flex-flow: column;
  justify-content: left;
  padding: 0;
  margin: 0;
  list-style: none;
  width: 20%; }

.menuItem {
  text-align: left;
  margin-bottom: 32px; }

.menuLinkStyle {
  text-decoration: none;
  color: var(--neutral-grey-600);
  font-size: 14px;
  line-height: 17.5px;
  font-weight: 400; }

.menuLinkStyleActive {
  text-decoration: none;
  color: var(--urbanx-black);
  font-size: 14px;
  line-height: 17.5px;
  font-weight: 500; }
