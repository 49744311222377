.fileUpload {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 16px;
  gap: 6px;
  background: transparent;
  border: 1px solid var(--brand-secondary-color);
  border-radius: 100px;
  width: fit-content; }
  .fileUpload.wide {
    width: -webkit-fill-available;
    width: -moz-available; }

.fileSelected {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  gap: 16px;
  border: 1px solid var(--neutral-grey-300);
  border-radius: 8px; }
  .fileSelected .error {
    color: var(--brand-error-color); }

.fileUpload.dark {
  background: #000; }
  .fileUpload.dark .fileUpload-text {
    color: #fff; }

.file-document-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;
  width: 40px;
  height: 40px;
  background: var(--neutral-grey-300);
  border-radius: 4px; }

input[type='file'] {
  display: none; }
