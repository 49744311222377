.noBorder {
  border: none !important; }

.hideWrapper .wrapper {
  display: none !important; }

.opacity0 {
  opacity: 0; }

.opacity1 {
  opacity: 1; }

.paddingTop18 {
  padding-top: 18px; }

.flexGap20 {
  gap: 20px; }

.flexCentre {
  align-items: center; }

.flexStart {
  align-items: flex-start; }

.flexEnd {
  align-items: flex-end; }

.padding1 {
  padding: 8px; }

.padding2 {
  padding: 18px; }

.padding3 {
  padding: 24px; }

.padding4 {
  padding: 32px; }

.padding5 {
  padding: 64px; }

.paddingRight1 {
  padding-right: 8px; }

.paddingRight2 {
  padding-right: 18px; }

.paddingRight3 {
  padding-right: 24px; }

.paddingRight4 {
  padding-right: 32px; }

.paddingRight5 {
  padding-right: 64px; }

.actionContainerTD {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  padding-right: 16px; }

.flexContainer {
  display: flex;
  flex-direction: column; }

.borderBottomContainer {
  border-bottom: 1px solid var(--neutral-grey-300);
  padding-bottom: 16px;
  display: flex; }

.subTitleStyle {
  font-size: 18px;
  line-height: 20.7px;
  font-weight: 400;
  color: var(--urbanx-black); }

.horizontalContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  border-bottom: 1px solid var(--neutral-grey-300); }

.container50Percent {
  width: 50%;
  padding: 20px;
  background: var(--neutral-grey-200);
  border-radius: 16px; }

.container60Percent {
  width: 60%;
  padding: 20px;
  background: var(--neutral-grey-200);
  border-radius: 16px; }

.container70Percent {
  width: 70%;
  padding: 20px;
  background: var(--neutral-grey-200);
  border-radius: 16px; }

.stretchContainerItems {
  align-items: stretch; }

.growOne {
  flex-grow: 1; }

.clickablePointer {
  cursor: pointer; }

.checkboxContainer {
  /* Set global checkbox background color as black*/ }
  .checkboxContainer .checkboxElementsContainer input:checked ~ .checkboxCheckmark {
    background-color: #000000; }
  .checkboxContainer .checkboxElementsContainer input:indeterminate ~ .checkboxCheckmark {
    background-color: #000000; }

.emailInviteButton svg {
  width: 24px !important;
  height: 24px !important; }

.licenseeDetails {
  padding-left: 10px; }
